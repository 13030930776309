@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Virgil';
  src: url('./fonts/Virgil.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Virgil', sans-serif;
  font-size: 1.15rem;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.5rem;
  display: inline-block;
}

.upload {

  display: flex;
  justify-content: center;
  padding: 50px 0;
  flex-direction: column;
}

.upload .subtitle {
  padding: 0;
  justify-content: center;
}

.mobileSettingsbuttons {
  display: none;
}

.canvas-div-container .subtitle {
  text-align: initial;
  padding-bottom: 70px;
  padding-left: 70px;
}

.subtitle {
  display: flex;
  padding-bottom: 35px;
  text-align: center;
}


.logo-upload{
  display: flex;
  justify-content: center;
  text-align: center;

}

.input-btn {
  display: block;
  text-align: center;
}

.input-btn::file-selector-button {
  display:inline-block;
  margin-right: 8px;
}

.interfase {
  padding: 50px 0;
}

.interfase-flex {
  display: flex;
  justify-content: space-around;
}

.canvas-div-container {
  padding-top: 50px;
}

.canvas-div-sticky {
  position: sticky;
  top: 50px;
}

canvas {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 4px 4px;
  overflow: hidden;
}

.settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  width: 33%;
  background-color: rgb(243, 241, 241);
  padding: 50px;
}

.setting-group-container {
  padding-bottom: 50px;
  border-bottom: 1px solid gray;
}

.last-setting-group-container {
  border-bottom: initial;
}

.setting {
  display: flex;
  align-items: center;;
}

.setting-group {
  display: flex;
  flex-direction:column;
  gap: 10px;
  justify-content: center ;
}

.color-picker-flex {
  display: flex;
  gap: 20px;
}

.image-selector {
  margin-top: 20px;
}

.setting-label {
  display: flex;
  min-width:13ch;
  justify-content: space-between;
  align-items: center;
}

.setting-svg {
  display: flex;
  justify-content: center;
  width: 70px;
}

.range-input {
  width: 45%;
  margin-left: 5px;

}
.number-input {
  width: 5ch;
  height: fit-content;
  margin-left: 15px;
}

.download-btns {
  display: flex;
}

.unit {
  width: 5ch;
}

.image-carousel {
  max-width: 250px;
}

.coordinate-system-container {
  position: relative;
}

.coordinate-system {
border-left: 2px solid black;
border-bottom: 2px solid black;
position:relative;
left: 0;
bottom: 0;
}

 /* Upward arrow (top of left border) */
 .coordinate-system::before {
  content: '';
  position: absolute;
  top: -14px;
  left: -7px;
  border: 6px solid transparent;
  border-bottom: 8px solid black; 
  transform: rotate(0deg); 
}

/* Rightward arrow (right of bottom border) */
.coordinate-system::after {
  content: '';
  position: absolute;
  bottom: -7px;
  right: -14px;
  border: 6px solid transparent;
  border-left: 8px solid black; 
  transform: rotate(0deg); 
}

 /* Label for the top-left arrow */
 .coordinate-system .y-label {
  position: absolute;
  top: -20px;
  left: -45px;
  font-size: 14px;
}

/* Label for the bottom-right arrow */
.coordinate-system .x-label {
  position: absolute;
  bottom: -30px;
  right: -20px;
  font-size: 14px;
}

.not-show {
  display: none;
}

.zufall-btn {
  max-width: 25%;
  margin-top: 30px;
}

.toggleDiv {
  padding-bottom: 50px;
  display: flex;
  gap: 20px;
  align-items: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (max-width: 900px) {


  .interfase {
    padding-top: 0;
    padding-bottom: 0;
  }

  .interfase-flex {
    justify-content:center;
    flex-direction: column-reverse;
  }

  .coordinate-system-container {
    display: flex;
    justify-content: center;
    position: initial;
  }

  .canvas-div-container {
    padding: initial;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 63vh;
  }

  .canvas-div-sticky {
    padding: initial;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    position: initial;
    top: initial;
  }

  canvas {
    margin: 0 auto;
  }

  .canvas-div-container .subtitle {
    padding: 0;
    font-size: 1rem;
    text-align: center;
    padding-bottom: 30px;

  }

  .settings {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 1rem;
    padding: initial;
    padding-top: 20px;
    position:relative;
    height: 37vh;
    overflow: scroll;
  }

  .mobileSettingsbuttons {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 10px;
    padding: 0 20px 0 20px;
  }

  .not-show-button {
    visibility: hidden;
  }

  .setting-group-container {
    padding: 40px 20px 20px 20px;
    border: initial;
    min-width: 95vw;
  }


  .input-btn::file-selector-button {
  
    display: block;
  }

  .coordinate-system .y-label {
    top: -25px;
    left: -30px;
  }

  .setting-label  {
    display: block;
    min-width: 10ch;
    max-width: 10ch;
  }

  .range-input {
    width: 25%;
  }

  .slider-button {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent; /* Adjust size */
    border-bottom: 10px solid transparent; /* Adjust size */
    cursor: pointer; /* Change cursor to pointer */
}

.left-arrow {
    border-right: 10px solid #007BFF; /* Color of the arrow */
}

.right-arrow {
    border-left: 10px solid #007BFF; /* Color of the arrow */
}

.input-btn::file-selector-button {
  display:block;
}

.logo-upload{
 flex-direction: column;

}

}